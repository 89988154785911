html {
  min-height: 100%;
  position: relative; }

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 130px;
  margin-top: 5rem; }

.bodyWrap {
  margin-bottom: 130px; }

.empty {
  display: none; }

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.6rem 15rem;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25); }
  .alert--success {
    background-color: #20bf6b; }
  .alert--error {
    background-color: #eb4d4b; }
